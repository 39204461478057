



















































































































































































































































































































































































































































































































// core
import Vue from "vue";
import moment, { Moment } from "moment";
// service
import { receiveItemChecklistService } from "@/services-v2/receive-item-checklist.service";
import { receiveItemsService } from "@/services-v2/receive-item.service";
import { batchService } from "@/services-v2/batch.service";
// interface
import { RequestReceivingItemChecklistCreate } from "@/models/interface-v2/receiving-item-checklist.interface";
import { ResponseBatch } from "@/models/interface-v2/batch.interface";
import { ResponseListContactData, IAddressDataList, ContactData } from "@/models/interface-v2/contact.interface";
// constant
import { DEFAULT_DATE_FORMAT } from "@/models/constant/date.constant";
import { GR_STATUS } from "@/models/enums/good-receipt.enum";
// utility
import { letterKeydown, validateTable } from "@/validator/globalvalidator";
import { Mode } from "@/models/enums/global.enum";
import { formatterNumber, reverseFormatNumber, formatCurrency } from "@/validator/globalvalidator";
import { DECIMAL_PLACES_QTY } from "@/models/constant/global.constant";
import MNotificationVue from "@/mixins/MNotification.vue";
import { FormModel } from "ant-design-vue";
import { RequestReceivingItemUpdate } from "@/models/interface-v2/receiving-item.interface";
import { printPDF } from "@/helpers/print-js";

export interface IRow {
  no: number
  key: number;
  productCode: string;
  productName: string;
  productId: string;
  qtyPO: number;
  unitUom: string;
  qtyReceived: number;
  branchRack: string | undefined;
  trackAsAsset: boolean;
  batchNumber: string;
  generatedFrom?: "ADD_ROW" | "BARCODE_NUMBER" | "DETAIL"
  barcodeNumber: string;
  batchSecureId: string;
  condition: string | undefined;
  id: string | null;
}

export default Vue.extend({
  name: "GoodReceiptChecklist",
  components: {
    ListQR: () => import(/* webpackPrefetch: true */"@/views/qrcode/modals/ModalListQr.vue"),
    AddQR: () => import(/* webpackPrefetch: true */"@/views/qrcode/FormQr.vue"),
    CSelectSupplier: () => import("@/components/shared/select-supplier/CSelectSupplier.vue"),
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      DECIMAL_PLACES_QTY,
      formModel: {
        supplierId: undefined as string | undefined,
        supplierName: undefined as string | undefined,
        poNumber: "",
        receiveDate: null as Moment | null,
        grChecklistNumber: "",
        supplierDONumber: "",
        supplierBillAddress: undefined as string | undefined,
        status: GR_STATUS.NEW as GR_STATUS,
        branchName: "",
        branchId: undefined as string | undefined,
        supplierImport: false,
      },
      formProp: {
        supplierName: {
          label: "lbl_supplier_name",
          name: "supplierId",
          placeholder: "lbl_type_here",
        },
        poNumber: {
          label: "lbl_po_number",
          name: "poNumber",
          placeholder: "lbl_type_here",
        },
        receiveDate: {
          label: "lbl_receive_date",
          name: "receiveDate",
          placeholder: "lbl_choose"
        },
        grChecklistNumber: {
          label: "lbl_good_receipt_checklist_number",
          name: "grChecklistNumber",
          placeholder: "lbl_type_here"
        },
        supplierDONumber: {
          label: "lbl_supplier_do_number",
          name: "supplierDONumber",
          placeholder: "lbl_type_here"
        },
        supplierBillAddress: {
          label: "lbl_supplier_bill_address",
          name: "supplierBillAddress",
          placeholder: "lbl_type_here"
        },
        status: {
          label: "lbl_status",
          name: "status",
        }
      },
      formRules: {
        branchId: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
            trigger: "change"
          },
        ],
        supplierImport: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        supplierId: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
            trigger: "change"
          },
        ],
        poNumber: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        receiveDate: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        grChecklistNumber: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        supplierDONumber: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        supplierBillAddress: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        condition: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ]
      },
      dtSource: [] as IRow[],
      selectedRowKeys: [] as number[],
      dtSupplier: {
        totalElements: 0,
        totalPages: 0,
        currentPage: 0,
        data: []
      } as ResponseListContactData,
      dtBillAddress: [] as IAddressDataList[],
      dtUom: [] as { key: string, value: string }[],
      vModal: false,
      menuTabs: [
        {
          key: "ListQR",
          title: "lbl_list_qr",
          scopedSlots: { tab: "customRender" },
          component: "ListQR"
        },
        {
          key: "AddQR",
          title: "lbl_create_qr",
          scopedSlots: { tab: "customRender" },
          component: "AddQR"
        },
      ],
      activeTabPane: "ListQR" as "ListQR" | "AddQR",
      selectedRowIndex: 0,
      loader: {
        submit: false,
        find: false,
        draft: false,
        print: false,
      },
      rowProductName: "",
      vmBarcodeNumber: "",
      rowsDistinct: [] as IRow[],
      rowBatchSecureId: "",
      deletedRow: [] as IRow[],
    };
  },
  computed: {
    sumQtyBatchReceived(): number {
      return this.dtSource.reduce((a, b) => a + b.qtyReceived, 0);
    },
    sumQtyBatchPO(): number {
      return this.dtSource.reduce((a, b) => a + b.qtyPO, 0);
    },
    formWrapper() {
      return {
        labelCol: {
          sm: {
            span: 24
          },
          md: {
            span: 9
          }
        },
        wrapperCol: {
          sm: {
            span: 24
          },
          md: {
            span: 15
          }
        }
      };
    },
    dynamicComp(): "ListQR" | "AddQR" | string {
      return this.menuTabs.find(el => el.key === this.activeTabPane)?.component || "";
    },
    compProps() {
      return {
        showButtonClose: false,
        overrideBack: true,
        propProductName: this.rowProductName,
        propSupplierName: this.formModel.supplierId,
        propRowsDistinct: this.rowsDistinct,
        propRowBatchSecureId: this.rowBatchSecureId
      };
    },
    compEvt() {
      return {
        onChoose: this.onChooseBatch
      };
    },
    isNew(): boolean {
      return this.formModel.status === GR_STATUS.NEW;
    },
    isDraft(): boolean {
      return this.formModel.status === GR_STATUS.DRAFT;
    },
    isUnbilled(): boolean {
      return this.formModel.status === GR_STATUS.UNBILLED;
    },
    isWaitForApproval(): boolean {
      return this.formModel.status === GR_STATUS.WAIT_FOR_APPROVAL;
    },
  },
  watch: {
    "$route.params.id"(newVal) {
      if (newVal) {
        this.receiveId = newVal;
        this.getDetailReceivingItem(this.receiveId);
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.receiveId = this.$route.params.id;
      this.getDetailReceivingItem(this.receiveId);
    }
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    formatCurrency,
    letterKeydown,
    moment,
    deleteRow(): void {
      const { dtSource } = this;
      this.deletedRow = dtSource.filter(data => data.generatedFrom === "DETAIL" && this.selectedRowKeys.includes(data.key));
      this.dtSource = dtSource.filter(data => !this.selectedRowKeys.includes(data.key));
      this.selectedRowKeys = [];
      this.setColNumber();
    },
    onRowSelect(rowKeys: number[]): void {
      this.selectedRowKeys = rowKeys;
    },
    addRow(): void {
      const { dtSource } = this;
      const newRow: IRow = {
        key: dtSource.length,
        productCode: "",
        productName: "",
        productId: "",
        qtyPO: 0,
        unitUom: "",
        qtyReceived: 0,
        branchRack: "",
        trackAsAsset: false,
        batchNumber: "",
        generatedFrom: "ADD_ROW",
        barcodeNumber: "",
        batchSecureId: "",
        condition: "",
        id: null,
        no: dtSource.length + 1,
      };
      this.dtSource = [newRow, ...dtSource];
      this.setColNumber();
    },
    getBillAddress(meta: ContactData): void {
      this.dtBillAddress = meta.addressDataList.filter(x => x.billTo);
      const [{ address, cityDistrict, country, postalCode }] = this.dtBillAddress.filter(x => x.primaryBillTo);
      this.formModel.supplierBillAddress = `${address}, ${cityDistrict}, ${country}, ${postalCode}` || "";
    },
    handleBack(): void {
      this.$router.push({name: "logistic.receivingitems.goodreceipt"});
    },
    /**
     * create new draft receipt
     */
    createDraftReceive(): void {
      const payload = this.createPayloadDraft();
      this.loader.draft = true;
      receiveItemChecklistService.addInventoryReceiveItem(payload)
      .then((res) => {
        this.$router.push({ name: "logistic.receivingitems.grchecklist.view", params: { id: res.id }});
        this.showSuccessMessage("notif_create_success");
      })
      .catch(() => this.showErrorMessage("notif_create_fail"))
      .finally(() => this.loader.draft = false);
    },
    submit(): void {
      const formRef = this.$refs.formCheckGR as FormModel;
      const colKeys = [
        { key: "productCode", message: "lbl_product_code" },
        { key: "productName", message: "lbl_product_name" },
        { key: "qtyPO", message: "lbl_qty_po" },
        { key: "unitUom", message: "lbl_uom" },
        { key: "qtyReceived", message: "lbl_qty_received" },
        { key: "branchRack", message: "lbl_location_received" },
        { key: "condition", message: "lbl_condition" },
      ];
      this.dtSource.forEach(x => {
        if (x.generatedFrom && x.generatedFrom == "ADD_ROW") {
          colKeys.push({ key: "batchNumber", message: "lbl_batch_number" });
        }
      });
      const {colValid, messages} = validateTable(this.dtSource, colKeys);
      formRef.validate((valid: boolean) => {
        if (valid && colValid) {
          if (this.receiveId) {
            this.handleSubmit(this.receiveId);
          } else {
            this.handleSubmitNew();
          }
        } else {
          const desc = `${this.$t(messages[0] || "")}. ${this.$t("lbl_at_row", {row: messages[1]})}`;
          this.showNotifValidationError(desc);
        }
      });
    },
    /**
     * handle submit from existing document
     */
    async handleSubmit(receiveId: string): Promise<void> {
      try {
        if (!receiveId) {
          this.showNotifError("notif_cannot_find_current_document");
          return;
        }
        this.loader.submit = true;
        const reqSubmit = this.createPayloadUpdate();
        await receiveItemChecklistService.submitReceivingItemChecklist(receiveId, reqSubmit);
        this.showSubmitSuccessMesssage();
        this.getDetailReceivingItem(receiveId);
      } catch (error) {
        this.showSubmitFailedMesssage();
      } finally {
        this.loader.submit = false;
      }
    },
    createPayloadDraft(): RequestReceivingItemChecklistCreate {
      const { formModel, dtSource } = this;
      const req: RequestReceivingItemChecklistCreate = {
        purchaseOrderNumber: formModel.poNumber,
        receiveDate: this.moment(formModel.receiveDate).format(),
        branchId: formModel.branchId || "",
        receiveItems: dtSource.map(item => ({
          locationReceivedId: item.branchRack || "",
          productId: item.productId,
          productUomId: item.unitUom,
          qty: item.qtyReceived,
          qtyPO: item.qtyPO,
          trackAsAsset: item.trackAsAsset,
          batchId: item.batchSecureId || "",
          barcodeNumber: (item.batchSecureId ? "" : item.barcodeNumber),
          qualityControl: { condition: item.condition || "" }
        })),
        supplierBillToAddress: formModel.supplierBillAddress || "",
        supplierDeliveryOrderNo: formModel.supplierDONumber,
        supplierId: formModel.supplierId || "",
        supplierImport: formModel.supplierImport,
        taxCalculation: null,
      };
      return req;
    },
    /**
     * handle submit from new created document
     */
    async handleSubmitNew(): Promise<void> {
      try {
        this.loader.submit = true;
        const reqDraft = this.createPayloadDraft();
        const res = await receiveItemChecklistService.addInventoryReceiveItem(reqDraft);
        const reqSubmit = this.createPayloadUpdate();
        /**
         * rewrite receive item line from
         * created draft
         */
        reqSubmit.receiveItems = res.itemListResponseDTOS?.map(x => ({
          batchId: x.batchNumberId,
          discount: 0,
          locationReceivedId: x.locationReceivedId || "",
          price: null,
          productId: x.productId,
          productUomId: x.productUomId,
          qty: x.productQty,
          qtyPO: x.qtyPO,
          qualityControl: x.qualityControl,
          secureId: x.id,
          trackAsAsset: x.trackAsAsset,
          rateTax: null,
          taxId: null,
          taxAmount: null,
          baseAmount: null,
          total: null,
        })) || [];
        await receiveItemChecklistService.submitReceivingItemChecklist(res.id, reqSubmit);
        this.showSubmitSuccessMesssage();
        this.$router.replace({ name: "logistic.receivingitems.grchecklist.view", params: { id: res.id } });
      } catch (error) {
        this.showSubmitFailedMesssage();
      } finally {
        this.loader.submit = false;
      }
    },
    onselectProduct(from: "productCode" | "productName", record: IRow, meta): void {
      if (from === "productCode") {
        record.productId = meta.meta.id || "";
        record.productName = meta.meta.name || "";
        record.batchNumber = "";
        record.batchSecureId = "";
      } else if (from === "productName") {
        record.productId = meta.meta.id || "";
        record.productCode = meta.meta.code || "";
        record.batchNumber = "";
        record.batchSecureId = "";
      }
      record.trackAsAsset = meta.meta.trackAsAsset;
    },
    findQR(record: IRow): void {
      // select all row distinct selected row
      const arrDistinct = this.dtSource.filter(item => item.key !== record.key);
      this.rowsDistinct = arrDistinct;

      // set query params GET list batch
      this.rowProductName = record.productName;

      // save selected index row from table
      const { no } = record;
      this.selectedRowIndex = no - 1;

      if (record.batchSecureId && record.generatedFrom === "BARCODE_NUMBER") {
        this.activeTabPane = "AddQR";
        this.rowBatchSecureId = record.batchSecureId || "";
      } else {
        this.activeTabPane = "ListQR";
        this.rowBatchSecureId = "";
      }

      this.vModal = true;
    },
    handleChangeMenu(value): void {
      this.activeTabPane = value;
    },
    /**
     * handle when batch is
     * choosen from List QR Modal
     */
    onChooseBatch(record: ResponseBatch): void {
      // match index with data source table
      // assign batch number from table list QR
      this.dtSource[this.selectedRowIndex].batchNumber = record.batchNumber;
      this.dtSource[this.selectedRowIndex].batchSecureId = record.id;

      this.vModal = false;
    },
    getDetailReceivingItem(id: string): void {
      if (!id) return;
      receiveItemsService.getDetailReceivingItem(id)
        .then(res => {
          this.formModel = {
            supplierId: res.supplierId,
            supplierName: res.supplierName,
            poNumber: res.purchaseOrderNumber,
            receiveDate: this.moment(res.receiveDate),
            grChecklistNumber: res.checklistNumber,
            supplierDONumber: res.supplierDeliveryOrderNo,
            supplierBillAddress: res.supplierBillToAddress,
            status: res.status,
            branchName: res.branchName,
            branchId: res.branchId,
            supplierImport: res.supplierImport,
          };
          this.dtSource = res.itemListResponseDTOS?.map((item, i) => ({
            key: i,
            productCode: item.productCode,
            productName: item.productName,
            productId: item.productId, // not necessary when view detail
            qtyPO: item.qtyPO,
            unitUom: item.productUomId,
            qtyReceived: item.productQty,
            branchRack: item.locationReceivedId,
            trackAsAsset: item.trackAsAsset,
            batchNumber: item.batchNumber,
            barcodeNumber: "", // not necessary when view detail
            batchSecureId: item.batchNumberId,
            condition: item.qualityControl?.condition || "",
            generatedFrom: "DETAIL",
            id: item.id,
            no: i + 1,
          })) || [];
          this.setColNumber();
        })
        .catch(() => {
          this.showErrorMessage("notif_process_fail");
        });
    },
    validateColumn(value: string | number): string {
      let type = "success";
      if (!value) type = "error";
      return type;
    },
    findBarcode(search = ""): void {
      if (search) {
        const rowidx = this.dtSource.findIndex(x => x.barcodeNumber === search);
        if (rowidx !== -1) {
          this.showNotifWarning("notif_duplicate_item", {data: this.dtSource[rowidx].barcodeNumber});
        } else {
          this.loader.find = true;
          batchService.findByBarcode(search)
          .then(res => {
            this.dtSource.unshift({
              key: this.dtSource.length,
              productCode: res.productCode,
              productName: res.productName,
              productId: res.productId,
              qtyPO: res.qty,
              unitUom: res.baseUnitId,
              qtyReceived: res.qty,
              branchRack: undefined,
              trackAsAsset: res.trackAsAsset || false,
              batchNumber: res.batchNumber || "",
              generatedFrom: "BARCODE_NUMBER",
              barcodeNumber: search,
              batchSecureId: res.id,
              condition: undefined,
              id: null,
              no: this.dtSource.length,
            });
            this.setColNumber();
            this.vmBarcodeNumber = "";
          })
          .catch(() => {
            this.showErrorMessage("notif_data_not_found");
          })
          .finally(() => {
            this.loader.find = false;
          });
        }
      }
    },
    setColNumber() {
      this.dtSource.forEach((x, i) => x.no = i + 1);
    },
    isGeneratedManual(record: IRow): boolean {
      return record.generatedFrom === "ADD_ROW";
    },
    isGeneratedAuto(record: IRow): boolean {
      return record.generatedFrom === "BARCODE_NUMBER" || record.generatedFrom === "DETAIL";
    },
    submitDraft(): void {
      if (this.receiveId) {
        this.handleUpdate(this.receiveId);
      } else {
        this.createDraftReceive();
      }
    },
    createPayloadUpdate(): RequestReceivingItemUpdate {
      const form = this.formModel;
      const { dtSource } = this;
      const payload: RequestReceivingItemUpdate = {
        branchId: form.branchId || "",
        currencyId: "",
        deletedReceiveItemIds: this.deletedRow.map(x => x.id || ""),
        description: "",
        goodsReceiptChecklistId: this.receiveId,
        purchaseOrderNumber: form.poNumber || "",
        supplierDeliveryOrderNo: form.supplierDONumber || null,
        rateCurrency: 0,
        receiveDate: form.receiveDate ? form.receiveDate.format() : "",
        receiveItems: dtSource.map(x => ({
          batchId: x.batchSecureId,
          discount: null,
          locationReceivedId: x.branchRack || "",
          price: null,
          productId: x.productId,
          productUomId: x.unitUom,
          qty: x.qtyReceived,
          qtyPO: x.qtyPO,
          qualityControl: { condition: x.condition || "" },
          secureId: x.id,
          trackAsAsset: x.trackAsAsset,
          rateTax: null,
          taxId: null,
          taxAmount: null,
          baseAmount: null,
          total: null,
        })),
        supplierBillToAddress: form.supplierBillAddress || "",
        supplierId: form.supplierId || "",
        supplierShipToAddress: null,
        top: null,
        supplierImport: form.supplierImport,
        taxCalculation: null,
      };
      return payload;
    },
    async handleUpdate(id: string): Promise<void> {
      try {
        if (!id) {
          this.showNotifError("notif_cannot_find_current_document");
          return;
        }
        this.loader.draft = true;
        const payload = this.createPayloadUpdate();
        await receiveItemsService.updatebyIdReceivingItems(payload, id);
        this.getDetailReceivingItem(id);
        this.showSuccessMessage("notif_update_success");
      } catch (error) {
        this.showErrorMessage("notif_update_fail");
      } finally {
        this.loader.draft = false;
      }
    },
    async handlePrint(): Promise<void> {
      try {
        this.loader.print = true;
        if (this.selectedRowKeys.length) {
          let batchNumbers: string[] = [];
          this.dtSource.forEach(x => {
            if (this.selectedRowKeys.includes(x.key)) {
              batchNumbers.push(x.batchNumber);
            }
          });
          if (!batchNumbers.length) return;
          const file = await batchService.getPrintFile(batchNumbers.join(","));
          printPDF(new Blob([file]), () => {
            this.showErrorMessage("error_print_fail");
          });
        } else {
          this.showNotifWarning("notif_choose_row");
        }
      } catch (error) {
        this.showErrorMessage("error_print_fail");
      } finally {
        this.loader.print = false;
      }
    },
  }
});
