import { Api } from "@/models/class/api.class";
import { RequestReceivingItemChecklistCreate, ResponseListReceivingItemChecklist } from "@/models/interface-v2/receiving-item-checklist.interface";
import { RequestReceivingItemUpdate, ResponseReceivingItem } from "@/models/interface-v2/receiving-item.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class ReceiveItemChecklistService extends HttpClient {
  constructor() {
    super();

    this.listInventoryReceiveItem = this.listInventoryReceiveItem.bind(this);
    this.addInventoryReceiveItem = this.addInventoryReceiveItem.bind(this);
  }

  /**
   * get list all receive item checklist
   * @param params query params
   * @returns list inventory receive item check list
   */
  listInventoryReceiveItem(params: RequestQueryParamsModel): Promise<ResponseListReceivingItemChecklist> {
    return this.get<ResponseListReceivingItemChecklist>(Api.ReceiveItemWarehouse, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * add new receiving item checklist
   * create draft
   * @param payload request new receiving item checklist
   * @returns receiving item
   */
  addInventoryReceiveItem(payload: RequestReceivingItemChecklistCreate): Promise<ResponseReceivingItem> {
    return this.post<ResponseReceivingItem, RequestReceivingItemChecklistCreate>(Api.ReceiveItemWarehouse, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * cancel receiving item by id
   * @param id receive item id
   * @returns response receive item
   */
  cancelReceivingItemChecklist(id: string): Promise<ResponseReceivingItem> {
    return this.put<ResponseReceivingItem, null>(`${Api.ReceiveItemWarehouse}/cancel/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  downloadReportReceivingItemChecklist(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(`${Api.ReceiveItemWarehouse}/report/download`, { params, responseType: "arraybuffer" })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  submitReceivingItemChecklist(id: string, payload: RequestReceivingItemUpdate): Promise<unknown> {
    return this.put<unknown, RequestReceivingItemUpdate>(`${Api.ReceiveItemWarehouse}/submit/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
}

export const receiveItemChecklistService = new ReceiveItemChecklistService();
