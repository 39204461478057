var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_good_receipt_checklist") } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "formCheckGR",
                attrs: {
                  model: _vm.formModel,
                  "label-align": "left",
                  rules: _vm.formRules
                }
              },
              "a-form-model",
              _vm.formWrapper,
              false
            ),
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_branch"),
                            prop: "branchId"
                          }
                        },
                        [
                          _vm.isNew || _vm.isDraft
                            ? _c("CSelectBranch", {
                                model: {
                                  value: _vm.formModel.branchId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "branchId", $$v)
                                  },
                                  expression: "formModel.branchId"
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formModel.branchName || "-") +
                                    " "
                                )
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProp.supplierName.label),
                            prop: "supplierId"
                          }
                        },
                        [
                          _vm.isNew || _vm.isDraft
                            ? _c("CSelectSupplier", {
                                attrs: {
                                  "for-list": false,
                                  "prop-customer-name":
                                    _vm.formModel.supplierName
                                },
                                on: {
                                  "on-select": function(ref) {
                                    var meta = ref.meta

                                    return _vm.getBillAddress(meta)
                                  }
                                },
                                model: {
                                  value: _vm.formModel.supplierId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "supplierId", $$v)
                                  },
                                  expression: "formModel.supplierId"
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.formModel.supplierName || "-") +
                                    " "
                                )
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProp.poNumber.label),
                            prop: "poNumber"
                          }
                        },
                        [
                          _vm.isNew || _vm.isDraft
                            ? _c("a-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formProp.poNumber.placeholder
                                  ),
                                  name: _vm.formProp.poNumber.name
                                },
                                model: {
                                  value: _vm.formModel.poNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "poNumber", $$v)
                                  },
                                  expression: "formModel.poNumber"
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(_vm.formModel.poNumber || "-"))
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProp.receiveDate.label),
                            prop: "receiveDate"
                          }
                        },
                        [
                          _vm.isNew || _vm.isDraft
                            ? _c("a-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formProp.receiveDate.placeholder
                                  ),
                                  name: _vm.formProp.receiveDate.name,
                                  format: _vm.DEFAULT_DATE_FORMAT
                                },
                                model: {
                                  value: _vm.formModel.receiveDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "receiveDate", $$v)
                                  },
                                  expression: "formModel.receiveDate"
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("date")(_vm.formModel.receiveDate)
                                  )
                                )
                              ])
                        ],
                        1
                      ),
                      _vm.formModel.grChecklistNumber
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formProp.grChecklistNumber.label
                                ),
                                prop: "grChecklistNumber"
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.formModel.grChecklistNumber || "-")
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formProp.supplierBillAddress.label
                            ),
                            prop: "supplierBillAddress"
                          }
                        },
                        [
                          _vm.isNew || _vm.isDraft
                            ? _c(
                                "a-select",
                                {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      _vm.formProp.supplierBillAddress
                                        .placeholder
                                    ),
                                    name: _vm.formProp.supplierBillAddress.name,
                                    "allow-clear": true,
                                    "filter-option": false
                                  },
                                  model: {
                                    value: _vm.formModel.supplierBillAddress,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "supplierBillAddress",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.supplierBillAddress"
                                  }
                                },
                                _vm._l(_vm.dtBillAddress, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: index,
                                      attrs: {
                                        value:
                                          item.address +
                                          ", " +
                                          item.cityDistrict +
                                          ", " +
                                          item.country +
                                          ", " +
                                          item.postalCode
                                      }
                                    },
                                    [
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  (item.address || "-") +
                                                    ", " +
                                                    (item.cityDistrict || "-") +
                                                    ", " +
                                                    (item.country || "-") +
                                                    ", " +
                                                    (item.postalCode || "-")
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                (item.address || "-") +
                                                  ", " +
                                                  (item.cityDistrict || "-") +
                                                  ", " +
                                                  (item.country || "-") +
                                                  ", " +
                                                  (item.postalCode || "-")
                                              ) +
                                              " "
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formModel.supplierBillAddress || "-"
                                  )
                                )
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formProp.supplierDONumber.label),
                            prop: "supplierDONumber"
                          }
                        },
                        [
                          _vm.isNew || _vm.isDraft
                            ? _c("a-input", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formProp.supplierDONumber.placeholder
                                  ),
                                  name: _vm.formProp.supplierDONumber.name
                                },
                                model: {
                                  value: _vm.formModel.supplierDONumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      "supplierDONumber",
                                      $$v
                                    )
                                  },
                                  expression: "formModel.supplierDONumber"
                                }
                              })
                            : _c("span", [
                                _vm._v(
                                  _vm._s(_vm.formModel.supplierDONumber || "-")
                                )
                              ])
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_supplier_import"),
                            prop: "supplierImport"
                          }
                        },
                        [
                          _c("a-checkbox", {
                            attrs: { disabled: !_vm.isNew && !_vm.isDraft },
                            model: {
                              value: _vm.formModel.supplierImport,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "supplierImport", $$v)
                              },
                              expression: "formModel.supplierImport"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t(_vm.formProp.status.label) } },
                        [
                          _c("span", { staticClass: "ant-form-text" }, [
                            _vm._v(
                              " " + _vm._s(_vm.formModel.status || "-") + " "
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _vm.isNew || _vm.isDraft
            ? _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c(
                        "a-space",
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                icon: "delete",
                                type: "danger",
                                disabled: !_vm.dtSource.length
                              },
                              on: { click: _vm.deleteRow }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_delete_row")) + " "
                              )
                            ]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: {
                                icon: "plus",
                                type: "primary",
                                disabled: !_vm.formModel.supplierId
                              },
                              on: { click: _vm.addRow }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 12 } },
                    [
                      _c(
                        "a-input-search",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t("lbl_barcode_number"),
                            "allow-clear": ""
                          },
                          on: { search: _vm.findBarcode },
                          model: {
                            value: _vm.vmBarcodeNumber,
                            callback: function($$v) {
                              _vm.vmBarcodeNumber = $$v
                            },
                            expression: "vmBarcodeNumber"
                          }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { slot: "enterButton", type: "primary" },
                              slot: "enterButton"
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_generate")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-table",
                    {
                      attrs: {
                        "data-source": _vm.dtSource,
                        scroll: { x: "calc(1300px + 50%)", y: 520 },
                        "row-selection": {
                          selectedRowKeys: _vm.selectedRowKeys,
                          onChange: _vm.onRowSelect
                        },
                        pagination: {
                          showTotal: function(total) {
                            return _vm.$t("lbl_total_items", { total: total })
                          }
                        }
                      }
                    },
                    [
                      _c(
                        "a-table-column",
                        {
                          key: "no",
                          attrs: { width: "50px", "data-index": "no" }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_number_short")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "productCode",
                          attrs: { "data-index": "productCode" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _c("CSelectMasterProductCode", {
                                    attrs: {
                                      "is-disabled": !_vm.isNew && !_vm.isDraft
                                    },
                                    on: {
                                      "on-select": function(ref) {
                                        var meta = ref.meta

                                        return _vm.onselectProduct(
                                          "productCode",
                                          record,
                                          meta
                                        )
                                      }
                                    },
                                    model: {
                                      value: record.productCode,
                                      callback: function($$v) {
                                        _vm.$set(record, "productCode", $$v)
                                      },
                                      expression: "record.productCode"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "is-required",
                              attrs: { slot: "title" },
                              slot: "title"
                            },
                            [_vm._v(_vm._s(_vm.$t("lbl_product_code")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "productName",
                          attrs: { "data-index": "productName" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _c("CSelectMasterProductName", {
                                    attrs: {
                                      "is-disabled": !_vm.isNew && !_vm.isDraft
                                    },
                                    on: {
                                      "on-select": function(ref) {
                                        var meta = ref.meta

                                        return _vm.onselectProduct(
                                          "productName",
                                          record,
                                          meta
                                        )
                                      }
                                    },
                                    model: {
                                      value: record.productName,
                                      callback: function($$v) {
                                        _vm.$set(record, "productName", $$v)
                                      },
                                      expression: "record.productName"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "is-required",
                              attrs: { slot: "title" },
                              slot: "title"
                            },
                            [_vm._v(_vm._s(_vm.$t("lbl_product_name")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "qtyPO",
                          attrs: { "data-index": "qtyPO", width: "150px" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _c("a-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: _vm.$t("lbl_type_here"),
                                      disabled: !_vm.isNew && !_vm.isDraft,
                                      formatter: _vm.formatterNumber,
                                      parser: _vm.reverseFormatNumber,
                                      min: 0,
                                      precision: _vm.DECIMAL_PLACES_QTY
                                    },
                                    model: {
                                      value: record.qtyPO,
                                      callback: function($$v) {
                                        _vm.$set(record, "qtyPO", _vm._n($$v))
                                      },
                                      expression: "record.qtyPO"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "is-required",
                              attrs: { slot: "title" },
                              slot: "title"
                            },
                            [_vm._v(_vm._s(_vm.$t("lbl_qty_po")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "unitUom",
                          attrs: { "data-index": "unitUom", width: "120px" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _c("CSelectUomConv", {
                                    attrs: {
                                      "is-disabled": !_vm.isNew && !_vm.isDraft,
                                      "prop-product-id": record.productId
                                    },
                                    model: {
                                      value: record.unitUom,
                                      callback: function($$v) {
                                        _vm.$set(record, "unitUom", $$v)
                                      },
                                      expression: "record.unitUom"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "is-required",
                              attrs: { slot: "title" },
                              slot: "title"
                            },
                            [_vm._v(_vm._s(_vm.$t("lbl_uom")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "qtyReceived",
                          attrs: {
                            "data-index": "qtyReceived",
                            width: "150px"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _c("a-input-number", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: _vm.$t("lbl_choose"),
                                      disabled: !_vm.isNew && !_vm.isDraft,
                                      formatter: _vm.formatterNumber,
                                      parser: _vm.reverseFormatNumber,
                                      min: 0,
                                      precision: _vm.DECIMAL_PLACES_QTY
                                    },
                                    model: {
                                      value: record.qtyReceived,
                                      callback: function($$v) {
                                        _vm.$set(
                                          record,
                                          "qtyReceived",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "record.qtyReceived"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "is-required",
                              attrs: { slot: "title" },
                              slot: "title"
                            },
                            [_vm._v(_vm._s(_vm.$t("lbl_qty_received")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "branchRack",
                          attrs: { "data-index": "branchRack", width: "150px" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _c("CSelectRack", {
                                    attrs: {
                                      "is-disabled": !_vm.isNew && !_vm.isDraft,
                                      "for-list": false,
                                      "by-branch": _vm.formModel.branchId
                                    },
                                    model: {
                                      value: record.branchRack,
                                      callback: function($$v) {
                                        _vm.$set(record, "branchRack", $$v)
                                      },
                                      expression: "record.branchRack"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "is-required",
                              attrs: { slot: "title" },
                              slot: "title"
                            },
                            [_vm._v(_vm._s(_vm.$t("lbl_location_received")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "condition",
                          attrs: {
                            "data-index": "condition",
                            prop: "condition",
                            width: "190px"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _c("CSelectCondition", {
                                    attrs: {
                                      disabled: !_vm.isNew && !_vm.isDraft
                                    },
                                    model: {
                                      value: record.condition,
                                      callback: function($$v) {
                                        _vm.$set(record, "condition", $$v)
                                      },
                                      expression: "record.condition"
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "is-required",
                              attrs: { slot: "title" },
                              slot: "title"
                            },
                            [_vm._v(_vm._s(_vm.$t("lbl_condition")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "trackAsAsset",
                          attrs: {
                            "data-index": "trackAsAsset",
                            align: "center",
                            width: "130px"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  _c(
                                    "a-checkbox",
                                    {
                                      attrs: {
                                        disabled: !_vm.isNew && !_vm.isDraft
                                      },
                                      model: {
                                        value: record.trackAsAsset,
                                        callback: function($$v) {
                                          _vm.$set(record, "trackAsAsset", $$v)
                                        },
                                        expression: "record.trackAsAsset"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("lbl_yes")) + " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(_vm.$t("lbl_track_as_asset")))]
                          )
                        ]
                      ),
                      _c(
                        "a-table-column",
                        {
                          key: "batchNumber",
                          attrs: {
                            "data-index": "batchNumber",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(text, record) {
                                return [
                                  record.batchSecureId &&
                                  (_vm.isGeneratedManual(record) ||
                                    _vm.isGeneratedAuto(record))
                                    ? _c("QrCode", {
                                        attrs: {
                                          value: record.batchNumber,
                                          quality: 1,
                                          scale: 2,
                                          size: 80,
                                          "white-margin": false
                                        }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "a-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.isGeneratedManual(record) ||
                                            (_vm.isGeneratedAuto(record) &&
                                              record.batchSecureId),
                                          expression:
                                            "isGeneratedManual(record) || (isGeneratedAuto(record) && record.batchSecureId)"
                                        }
                                      ],
                                      attrs: {
                                        type: "link",
                                        disabled:
                                          (!_vm.isNew && !_vm.isDraft) ||
                                          !record.productCode ||
                                          !record.productName
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.findQR(record)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            record.batchSecureId ||
                                              record.batchNumber
                                              ? _vm.isGeneratedAuto(record)
                                                ? _vm.$t("lbl_view_qr")
                                                : _vm.$t("lbl_edit_qr")
                                              : _vm.$t("lbl_find_qr")
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !record.batchSecureId &&
                                            _vm.isGeneratedAuto(record),
                                          expression:
                                            "!record.batchSecureId && isGeneratedAuto(record)"
                                        }
                                      ],
                                      staticClass: "ant-form-explain"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "lbl_qr_generate_after_submit"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !record.batchSecureId &&
                                            !_vm.isNew &&
                                            !_vm.isDraft,
                                          expression:
                                            "!record.batchSecureId && !isNew && !isDraft"
                                        }
                                      ],
                                      staticClass: "ant-form-text"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("lbl_not_available")) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "is-required",
                              attrs: { slot: "title" },
                              slot: "title"
                            },
                            [_vm._v(_vm._s(_vm.$t("lbl_qrcode")))]
                          )
                        ]
                      ),
                      _c("template", { slot: "footer" }, [
                        _c("table", [
                          _c("tr", [
                            _c("td", { attrs: { width: "150px" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("lbl_total_items", { total: "" })
                                  ) +
                                  " "
                              )
                            ]),
                            _c("td", [_vm._v(_vm._s(_vm.dtSource.length))])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("lbl_sum_qty_received", { total: "" })
                                )
                              )
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toDecimalQty")(
                                    _vm.sumQtyBatchReceived
                                  )
                                ) + " KG"
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.$t("lbl_sum_qty_po", { total: "" }))
                              )
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toDecimalQty")(_vm.sumQtyBatchPO)
                                ) + " KG"
                              )
                            ])
                          ])
                        ])
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleBack } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.loader.draft,
                            disabled: !_vm.isNew && !_vm.isDraft
                          },
                          on: { click: _vm.submitDraft }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lbl_save_as_draft")) + " "
                          )
                        ]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "printer",
                            loading: _vm.loader.print,
                            type: "primary"
                          },
                          on: { click: _vm.handlePrint }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loader.submit,
                            disabled: !_vm.isNew && !_vm.isDraft,
                            icon: "save"
                          },
                          on: { click: _vm.submit }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.$t("lbl_find_qr"),
            width: "90%",
            "destroy-on-close": ""
          },
          model: {
            value: _vm.vModal,
            callback: function($$v) {
              _vm.vModal = $$v
            },
            expression: "vModal"
          }
        },
        [
          _c(
            "a-card",
            {
              attrs: {
                "tab-list": _vm.menuTabs,
                "active-tab-key": _vm.activeTabPane
              },
              on: {
                tabChange: function(key) {
                  return _vm.handleChangeMenu(key)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "customRender",
                  fn: function(item) {
                    return _c("span", {}, [
                      _vm._v(
                        _vm._s(
                          _vm.rowBatchSecureId
                            ? item.key === "AddQR"
                              ? _vm.$t("lbl_view_qr")
                              : _vm.$t(item.title)
                            : _vm.$t(item.title)
                        ) + " "
                      )
                    ])
                  }
                }
              ])
            },
            [
              _c(
                "transition",
                { attrs: { name: "component-fade", mode: "out-in" } },
                [
                  _c(
                    "keep-alive",
                    [
                      _c(
                        _vm.dynamicComp,
                        _vm._g(
                          _vm._b(
                            { tag: "component" },
                            "component",
                            _vm.compProps,
                            false
                          ),
                          _vm.compEvt
                        )
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.vModal = false
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }